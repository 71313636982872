import { Box } from '@mui/material';
import React from 'react';

export default function ContentText(props) {
    return (
        <>
            <Box
                sx={
                    {
                        textAlign: 'justify',
                        boxShadow: 5,
                        padding: 2,
                        bgcolor: 'white',
                        borderRadius: 5,
                        mb: 2
                    }
                }>
                {props.children}
            </Box>
        </>
    );
}
