import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

export default function ProduitItemComponent(props) {
    return (
        <>
            <Card
                sx={{
                    mb: 2,
                    height: 450,
                    cursor: 'pointer',
                    borderRadius: 5,
                    borderTopLeftRadius: 60,
                    border: 2,
                    borderColor: 'primary.main',
                    backgroundImage: `url('/logo.jpg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '50px 50px',
                    backgroundPosition: 'top 145px right 10px',
                    backgroundClip: 'content-box',
                    'img': {
                        borderBottom: 2,
                        borderColor: 'secondary.dark',
                        borderBottomRightRadius: "50%",
                        borderBottomLeftRadius: 30,
                        borderTopLeftRadius: 60,
                        boxShadow: 1,
                    },
                    '&:hover': {
                        boxShadow: 5,
                        bgcolor: 'primary.main',
                        'img': {
                            borderRadius: 5,
                            borderBottomRightRadius: 30,
                            borderBottomLeftRadius: "50%",
                            borderTopLeftRadius: 60,
                            boxShadow: 3,
                            objectFit: 'cover',
                            transform: "scale(0.98)",
                        },
                        'button': {
                            display: 'inline-flex',
                            color: 'secondary.dark',
                            borderColor: 'secondary.dark',
                        },
                        '#Description': {
                            color: 'white'
                        },
                        transform: "scale(1.05)",
                        transitionDuration: "25s",
                        '.MuiCardContent-root': {
                            color: 'secondary.dark'
                        }
                    }
                }}
            >
                <CardMedia
                    component="img"
                    height="200"
                    image={props.produit.img}
                    alt={props.produit.title}
                    sx={{
                        bgcolor: 'white',
                    }}
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" noWrap>
                        {props.produit.title}
                    </Typography>
                    <Typography
                        id="Description"
                        variant="body1"
                        color="text.secondary"
                        sx={{
                            height: 130,
                            pb: 1,
                            // textAlign: 'justify',
                        }}
                    >
                        {props.produit.description && props.produit.description.substring(0, 125)}
                        {props.produit.description && props.produit.description.substring(0, 125).length === 125 ? "..." : ""}
                    </Typography>
                    <Button
                        sx={{
                            display: 'none',
                            color: 'primary.dark',
                            borderColor: 'primary.dark',
                        }}
                        variant="outlined">
                        Voir Détails...
                    </Button>
                </CardContent>
            </Card>
        </>
    );
}
