import React from 'react';

export default function Map(props) {
    return (
        <>
            <iframe
                title={props.title}
                src={`${props.lien}`}
                width="100%"
                style={{ border: 2, borderRadius: 15 }}
                loading="lazy"
            >
            </iframe>
        </>
    );
}
