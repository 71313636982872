import React from 'react';
import { Button } from '@mui/material';

export default function ReseauSocialItem(props) {
    return (
        <>
            <Button
                variant="rounded"
                sx={{
                    bgcolor: 'secondary.dark',
                    color: 'primary.main',
                    '&:hover': {
                        color:
                            (props.name === "YouTube" && '#ff0000') ||
                            (props.name === "Twitter" && '#1d9bf0') ||
                            (props.name === "Instagram" && '#fccc63') ||
                            (props.name === "Facebook" && '#046ce4'),
                        boxShadow: 3,
                        backgroundColor: 'secondary.main',
                        fontSize: 15
                    }
                }}
            >
                {props.children}
            </Button>
        </>
    );
}
