import React from 'react';
import {
    Alert,
    Avatar,
    Box,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AssistantIcon from '@mui/icons-material/Assistant';
import ReactImageMagnify from 'react-image-magnify';

export default function ProduitDetails(props) {

    const [selectedImage, setSelectedImage] = React.useState(props.produit.img)

    return (
        <>
            <Grid container spacing={3}>
                <Grid item lg={4} md={5} sm={6} xs={12}>
                    <ReactImageMagnify
                        style={
                            {
                                width: "100%",
                                boxShadow: '3px 3px #ea851d',
                                borderRadius: '5px',
                                padding: 5,
                            }
                        }
                        {...{
                            smallImage: {
                                alt: props.produit.title,
                                isFluidWidth: true,
                                src: selectedImage || props.produit.img,
                                sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                            },
                            largeImage: {
                                src: selectedImage || props.produit.img,
                                width: 1200,
                                height: 1800
                            },
                            lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                        }}
                        {...{
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false,
                            enlargedImagePosition: 'over'
                        }}
                    />
                    <Grid
                        container
                        spacing={2}
                        mt={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <img
                                onClick={() => { setSelectedImage(props.produit.img) }}
                                src={props.produit.img}
                                alt={props.produit.title}
                                title={props.produit.title}
                                loading="lazy"
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    cursor: 'pointer',
                                    boxShadow: '3px 3px #ea851d',
                                    borderRadius: '50%',
                                    padding: 2,
                                    '&:hover': {
                                        borderRadius: '5px',
                                        boxShadow: '2px 2px #32682e',
                                        transform: "scale3d(1.05, 1.05, 1)"
                                    }
                                }}
                            />
                        </Grid>
                        {
                            props.produit.othersImages && props.produit.othersImages.map((image, i) =>
                                <Grid key={i} item>
                                    <img
                                        onClick={() => { setSelectedImage(image) }}
                                        src={image}
                                        alt={props.produit.title}
                                        title={props.produit.title}
                                        loading="lazy"
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            cursor: 'pointer',
                                            boxShadow: '3px 3px #ea851d',
                                            borderRadius: '50%',
                                            padding: 2,
                                        }}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
                <Grid item lg={8} md={7} sm={6} xs={12}>
                    <Typography
                        gutterBottom
                        variant="h4"
                        component="div"
                        noWrap
                        sx={{
                            borderBottom: 3,
                            borderBottomColor: 'secondary.dark',
                            color: 'secondary.dark',
                            boxShadow: 1,
                            padding: 0,
                            borderRadius: "5px"
                        }}
                    >
                        <List sx={{ p: 0 }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar
                                        src={props.produit.img}
                                        sx={{
                                            bgcolor: 'primary.main',
                                            boxShadow: 3,
                                            border: 3,
                                            borderLeftColor: 'secondary.dark',
                                            borderRightColor: 'secondary.main',
                                            borderTopColor: 'primary.main',
                                            borderBottomColor: 'primary.dark',
                                        }}
                                    >
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primaryTypographyProps={{
                                        noWrap: true,
                                        variant: "h4",
                                        component: "div",
                                        padding: 0
                                    }}
                                    primary={props.produit.title}
                                    secondary={<span style={{ padding: '3px', backgroundColor: '#ff000' }}>{props.produit.categorie}</span>}
                                />
                            </ListItem>
                        </List>
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 2, textAlign: 'justify' }}>
                        {props.produit.description}
                    </Typography>
                    {/* {
                        props.produit.proprietes && props.produit.proprietes.length !== 0 && */}
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        noWrap
                        sx={{
                            borderBottom: 3,
                            borderBottomColor: 'secondary.main',
                            color: 'secondary.main',
                            boxShadow: 1,
                            padding: 1,
                            borderRadius: "5px"
                        }}
                    >
                        <AssistantIcon />{" "}{"Carateristiques:"}
                    </Typography>
                    {/* } */}
                    {
                        props.produit.proprietes && props.produit.proprietes.length !== 0 ?
                            props.produit.proprietes.map((propriete, i) =>
                                <>
                                    <Box key={i} sx={{
                                        ml: 2
                                    }}>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            component="div"
                                            noWrap
                                            sx={{
                                                borderBottom: 3,
                                                borderBottomColor: 'primary.main',
                                                color: 'primary.main',
                                                boxShadow: 1,
                                                padding: 0.5,
                                                borderRadius: "5px",
                                                mt: 2,
                                                bgcolor: 'secondary.dark'
                                            }}
                                        >
                                            <ChevronRightIcon sx={{ fontSize: 28 }} />{propriete.label}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                            sx={{ textAlign: 'justify' }}
                                        >
                                            {
                                                `${propriete.data}`
                                            }
                                        </Typography>
                                    </Box>
                                </>
                            )
                            :
                            <>
                                <Alert variant="outlined" severity="warning">
                                    Pas de caracteristiques enregistrer...
                                </Alert>
                            </>
                    }
                </Grid>
            </Grid>
        </>
    );
}
