import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

export default function TitleComponent(props) {
    return (
        <>
            <Typography
                variant={props.variant || "h5"}
                component="div"
                align='center'
                gutterBottom
                sx={{
                    boxShadow: 3,
                    mt: 1,
                    borderBottomLeftRadius: 15,
                    borderTopRightRadius: 15,
                    border: 3,
                    borderColor: "secondary.main",
                    fontWeight: 'fontWeightLight',
                    color: 'primary.main',
                    bgcolor: 'white'
                }}
            >
                <ListItem>
                    <ListItemAvatar>
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                            {props.icon}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primaryTypographyProps={{ variant: props.variant || "h5", noWrap: true }}
                        primary={props.label || props.children}
                    />
                </ListItem>
            </Typography>
        </>
    );
}