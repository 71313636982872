import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import TitleComponent from '../../autres/TitleComponent';
import ProduitItemComponent from './ProduitItemComponent';
import Categories from '../categories/CategoriesComponent';
import ArticleIcon from '@mui/icons-material/Article';
import ProduitDetails from './ProduitDetailsComponent';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DialogComponent from '../../dialog/DialogComponent';

const produits = [
    // Securite incendie =>
    // {
    //     img: "/rubriques/produits/authentique/categories/securite_incendie/GROUPE_SITRA_001.jpeg",
    //     title: "Access control 1",
    // categorie: "securite_incendie",
    //     description: `Voir caracteristiques pour plus de détails !`,        
    // proprietes: [
    // {
    //     label: ` :`,
    //     data: ``
    // }
    // ],
    // othersImages: [
    // ]

    // },
    {
        img: "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_002.jpeg",
        title: "Gants dynamiques EN 659",
        categorie: "equipements_pompiers",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            {
                label: `Modèle : `,
                data: `EPI-80.0302.049`
            },
            {
                label: `Tailles : `,
                data: `De 8 à 11`
            },
            {
                label: `Normes :`,
                data: `- EN 659:2003 III Cat.
                - A1 :2008 III Cat.`
            },
            {
                label: `Niveaux protections : `,
                data: `- Anti abrasion niveau 3
                - Anti coupure niveau 2
                - Anti déchirure niveau 3
                - Anti perforation niveau 3
                - Comportement au feu niveau 4
                - Dextérité niveau 5`
            },
            {
                label: `Autres caractéristiques : `,
                data: `
                - Gant à 5 doigts
                - Paume + dos fabriqué en cuir de vachette
                - Résistant à la chaleur
                - Manche réglable
                - Renforcement des artères
                - Bande réfléchissante jaune (25mm)
                - Doublure + isolant
                - Fabriqué en tissu Para aramide
                - Membrane respirante
                - Coutures réalisées en Kelvar`
            },
        ],
        othersImages: [
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_001.jpeg"
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_004.jpeg",
        title: "Casque pompier",
        categorie: "equipements_pompiers",
        description: `Le casque de protection incendie certifié selon les normes Européennes :
        EN 443:2008 (Casques de protection incendie pour bâtiments et autres structures)
        EN 16471:2014 (Casques pour la lutte contre les incendies en forêt)
        EN 16473:2014 (Casques pour le secours technique)
                
        MED
        Le casque est équipé d’une lunette transparente et d’une visière faciale orée réfléchissante certifiées selon
        les normes européennes EN 166: 2004 et EN 14458: 2004 et il se décompose de la manière suivante :
             Coque externe
             Jugulaire réglable
             Mentonnière ajustable
            Calotin réglable en maille
             Molette de réglage de la taille
             Amortisseur de choc interne
             Leviers de manœuvre externe de la lunette
             Kit de fixation pour masque ARI
            Tous les composants sont détachables et remplaçables.
            `,
        proprietes: [
            {
                label: `Modèle :`,
                data: `EPI - 19 - 010 - 001`
            },
            {
                label: `Coque :`,
                data: `Réalisée en matériau thermoplastique armé de fibre de verre, résistant aux hautes températures et aux
                    contraintes mécaniques. Elle assure une haute protection multidirectionnelle de la tête de l’utilisateur
                    et la protection du crâne, de la nuque, de la région frontale et des surfaces temporales et auriculaires.
                    La forme de la calotte est enveloppante et dispose d’une nervure longitudinale avec des bords arrondis
                    (crête), qui est plus fine sur l’arrière et termine en s’évasant sur la partie avant. Transversalement par
                    rapport à l'axe horizontal, une autre nervure converge avec la crête dans la zone médiane du casque.
                    À l'arrière, le bord arrondi facilite l’écoulement de l’eau. Sur les côtés, un système de connexion
                    universel permet la fixation de dispositifs d'éclairage et de masques respiratoires.`
            },
            {
                label: `Jugulaire :`,
                data: `Jugulaire réglable en 4 points avec boucle de fixation à déclenchement rapide.
                    Mentonnière ajustable en cuir.`
            },
            {
                label: `Calotin réglable en maille :`,
                data: `
                        Bandes de suspension réglables en 4 points et maille avec bords en cuir.`
            },
            {
                label: `Système de réglage de la taille :`,
                data: `Molette de serrage du tour de tête multi tailles (52 – 64).`
            },
            {
                label: `Eléments de confort :`,
                data: `Ignifugés, fabriqués en cuir`
            },
            {
                label: `Poids :`,
                data: `Casque seul : 1.220 gr +/- 10 gr
                        Casque avec visière et protection faciale : 1.540 gr +/- 10 gr`
            },
            {
                label: `Accessoires :`,
                data: `
                        Le casque est pré-équipé et certifié pour être utilisé avec les accessoires sui-
                        vants :
                        Bas volet de nuque aluminisé
                        Bas volet en aramide
                        Lampe torche
                        Bandes réfléchissantes
                        Système de communication
                        Masque respiratoire complet
                        Couleur : rouge, jaune, blanc, bleu, noir, orange, jaune fluo, photoluminescent et gris métal chromé`
            }
        ],
        othersImages: [
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_004_CASQUE_EVO_1.jpeg",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_004_CASQUE_EVO_2.png",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_004_CASQUE_EVO_3.png",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_004_CASQUE_EVO_4.png",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_004_CASQUE_EVO_5.png",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_004_CASQUE_EVO_6.png",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_005.jpeg",
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/equipements_pompiers/Ensemble-EN469.jpeg",
        title: "Ensemble EN469",
        categorie: "equipements_pompiers",
        description: `EN 469:2006 Xf2 Xr2 Y2 Z2 Niveau 2
            Veste de pompier
            Tissus et couches : Extérieur : 75% Nomex® - 23% Kevlar®- 2% Antistatique`,
        proprietes: [
            {
                label: `Modèle :`,
                data: `EPI-90.216.001`
            },
            {
                label: `Barrière anti-humidité :`,
                data: `Tissu FR avec membrane enduite de PU hautement respirante`
            },
            {
                label: `Barrière thermique :`,
                data: `Feutre d'aramide matelassé à une doublure intérieure en Aramide/Viscose FR 50/50`
            },
            {
                label: `Certifié par des organismes accrédités et notifiés selon :`,
                data: `EN 469 Niveau 2 (Xf2, Xr2, Y2, Z2) / EN 471 Classe 1`
            },
            {
                label: `Fil à coudre :`,
                data: `100% Aramide`
            },
            {
                label: `Velcros :`,
                data: `Retardateur de flamme`
            },
            {
                label: `Fermeture éclair :`,
                data: `Système d'ouverture rapide panique à sens unique`
            },
            {
                label: `Bandes réfléchissantes :`,
                data: `EN 469, EN 11612, EN 14116, support aramide 5 cm jaune-gris-jaune`
            },
            {
                label: `Couleur :`,
                data: `Bleu foncé`
            },
            {
                label: `Tailles :`,
                data: `Selon la norme EN ISO 13688:2013 S, M, L, XL, XXL`
            },
            {
                label: `Design standard :`,
                data: `- Deux poches couvertes de chaque côté avec une poche poitrine gauche pour la radio.
                    et un support de microphone
                    - Fixation velcro et fermeture éclair de sécurité sur le devant de la veste.
                    - Système de fixation rapide anti-panique à ouverture unidirectionnelle sur le devant de la veste
                    - Poignets en tricot aramide sur chaque manche. Velcro ignifugé et
                    anneau de pouce imperméable
                    - Col large avec système de fermeture du col par Velcros FR
                    - Pièces sous les manches pour augmenter la capacité de mouvement
                    - Fil à coudre en aramide de haute qualité.
                    - Entièrement scellé avec le type de couture
                    - Dos en aramide ignifugé 50 mm jaune-gris-jaune selon la norme EN`
            },
        ],
        othersImages: [
            "/rubriques/produits/authentique/categories/equipements_pompiers/Ensemble-EN469_1.jpeg",
            "/rubriques/produits/authentique/categories/equipements_pompiers/Ensemble-EN469_2.jpeg",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_003.jpeg",
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/equipements_pompiers/Cagoule_SS2.png",
        title: "Cagoule SS2",
        categorie: "equipements_pompiers",
        description: `Cagoule de protection anti-feu idéal pour les interventions de sapeurs-pompiers, fabriquée en double
        couche pour une meilleure protection thermique.
        Equipée d’une ouverture au niveau des yeux de type hublot et d’une protection du cou.
        EPI de catégorie III`,
        proprietes: [
            {
                label: `Modèle :`,
                data: `EPI-80.0105.029`
            },
            {
                label: `Composition: `,
                data: `Tissu ignifugé 200g élastique.`
            },
            {
                label: `Conformités : `,
                data: `EN 13911: 2004 et EN 13688: 2013.
                        Réf: EPI-80.0105.029`
            }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_006.jpeg",
        title: "Combinaison ignifugé ST1",
        categorie: "equipements_pompiers",
        description: `Combinaison en tissu ignifugé PARAMANTEX.
        Les détails de fabrication ont été conçus et fabriqués après des recherches minutieuses et des expé-
        riences dans les incendies de forêt. Le vêtement d’une seule pièce assure un maximum de mobilité.`,
        proprietes: [
            {
                label: `Modèle : `,
                data: `EPI-80.0104.001`
            },
            {
                label: `Couleurs combinaison disponibles: `,
                data: `Orange / Bleu
                Les coutures sont doublées et entièrement réalisées en NOMEX et coutures principales doublées.
                Niveaux de protection: A1 B1 C1`
            },
            {
                label: `Autres caracteristique`,
                data: ` Col rabattu
                 Manches longues avec élastiques aux poignets
                 Soufflets au dos
                 Fermeture éclair avant en laiton avec rabat de protection
                 Élastiques à la taille et aux chevilles
                 2 poches niveau poitrine avec rabats en Velcro ® FR
                 2 poches sur haut des jambes avec rabats en Velcro ® FR`
            }
        ],
        othersImages: [
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_007.jpeg",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_006_Combinaison_ignifuge_ST1.jpeg"
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/equipements_pompiers/bottes_sapeurs_pompier_FIAMMA.png",
        title: "Bottes sapeurs pompier FIAMMA",
        categorie: "equipements_pompiers",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            {
                label: `Modèle : `,
                data: `EPI-80.0403.008`
            },
            {
                label: `- Lacets : `,
                data: `Double lacets et fermeture éclair pour enlèvement rapide`
            },
            {
                label: `- Semelle interne forme anatomique amovible`,
                data: ``
            },
            {
                label: `- Bout en acier de protection`,
                data: ``
            },
            {
                label: `- Fousbet interne en Thinsulate`,
                data: ``
            },
            {
                label: `- Semelle extérieure anti-dérapante en caoutchouc, parfaite pour terrains accidentés`,
                data: ``
            },
            {
                label: `- Bonne isolation contre le froid`,
                data: ``
            },
            {
                label: `- Résistance :`,
                data: `Chaleur, huile, hydrocarbures`
            },
            {
                label: `- Protection malléole grâce au renforcement et au rembourrage`,
                data: ``
            },
            {
                label: `- Spunterba :`,
                data: `Renforcement sur l’orteil`
            },
            {
                label: `- Doublure Windtex en tissu hypoallergenique, respirant, déperlant.`,
                data: ``
            },
            {
                label: `- Les bottes sont également conçues en version SP :`,
                data: `(Sans métal sur l’orteil) et PLUS avec une hauteur de 4cm.`
            },
            {
                label: `- Hauteur de la tige (t.43): `,
                data: `“FIAMMA” et “FIAMMA SP” 22.5cm; “FIAMMA PLUS” 26.5cm`
            },
            {
                label: `- Épaisseur :`,
                data: `2.0/2.2mm, avec traitement hydrofuge.`
            },
            {
                label: `- Isolement anti-performant grâce à plusieurs couches de tissus Matrix (Kevlar).`,
                data: ``
            },
            {
                label: `- Poids (t.46) : `,
                data: `2.08kg`
            },
            {
                label: `- Code Douane :`,
                data: `6201121090`
            },
        ],
        othersImages: [
            "/rubriques/produits/authentique/categories/equipements_pompiers/bottes_sapeurs_pompier_FIAMMA1.png",
            "/rubriques/produits/authentique/categories/equipements_pompiers/bottes_sapeurs_pompier_FIAMMA2.png",
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_008.jpeg",
        title: "Combinaison F1 d’exercice / interventions",
        categorie: "equipements_pompiers",
        description: `Combinaison d’intervention et d’intervention pour Sapeurs-Pompiers. 
        Ce produit est fabriqué avec notre tissu de haute qualité.`,
        proprietes: [
            {
                label: `Modèle : `,
                data: `EPI-90.0103.001`
            },
            {
                label: `Composition :`,
                data: `- 50% tissu aramide
                - 49% tissu viscose F.R
                - 1% Fibres antistatiques`
            },
            {
                label: `La combinaison est équipée de:`,
                data: `- Bande rétro - réfléchissante couleur gris argent au niveau de la poitrine, haut des manches, sur les
                jambes.
                - Fermeture à glissière YKK
                - 2 Soufflets situés en haut du dos
                - Manches type « pivot »
                - Poignets à fermeture réglable par Velcro
                - Col transformable
                - Coupe vent
                - 2 poches de poitrine à ouverture verticale
                - Poches zippées
                - 2 poches de côté en biais sur les jambes
                - Renfort en tissu aux genoux
                - Système de serrage par ruban élastique
                - Bas des jambes équipé d’un élastique pouvant être remplacé
                par un lacet
                - Passepoil rouge Sapeurs Pompiers pris dans chaque couture
                Au niveau du côté extérieur des jambes.
                - Poids 1.150 kg`
            },
            {
                label: `Normes / Niveaux protections:`,
                data: `- NIT302
                - EN 340
                - EN 533 Classe 3
                - EN 531
                - EN 471
                - A1
                - B1
                - C1`
            },
        ],
        othersImages: [
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_008_Combinaison_F1_d_exercice_interventions_bas.jpeg",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_008_Combinaison_F1_d_exercice_interventions_haut.jpeg",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_008_Combinaison_F1_d_exercice_interventions_homme.jpeg",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_008_Combinaison_F1_d_exercice_interventions_femme.jpeg",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_008_Combinaison_F1_d_exercice_interventions_shemas.jpeg"
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_009.jpeg",
        title: "SIGMA VISION III",
        categorie: "equipements_pompiers",
        description: `Le ProPak-Sigma a été spécifiquement conçu pour être utilisé comme appareil de conformité dans
        les environnements de lutte anti-incendie dans la marine ou l'industrie. L'ensemble de la gamme
        ProPak a été certifié conformément à la norme EN 137:2006 Type 2, comprenant le test rigoureux
        d'immersion dans les flammes, et de la MED, ce qui signifie qu'il bénéficie de la plus récente
        certification pour le marché de la marine.`,
        proprietes: [
            {
                label: `Modèle : `,
                data: `EPI - 20.271.570`
            },
            {
                label: `Description :`,
                data: `Appareil de protection respiratoire certifié EN 137:2006 type 2
                MED
                Conforme pour les environnements de lutte anti-incendie marin et
                industriel
                Construction rigide de la plaque dorsale
                Système pneumatique
                Facilement amovible
                Simple à nettoyer et à entretenir`
            },
            {
                label: `Poids configuration mono bouteille (sans bouteille)`,
                data: `2.6 kg`
            },
            {
                label: `Poids configuration mono bouteille et masque (sans bouteille)`,
                data: `3.2 kg`
            },
            {
                label: `Longueur`,
                data: `630 mm`
            },
            {
                label: `Largeur`,
                data: `285 mm`
            },
            {
                label: `Profondeur (avec bouteille de 6 litres et 200 bar)`,
                data: `215 mm`
            },
        ],
        othersImages: [
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_010.jpeg",
            "/rubriques/produits/authentique/categories/equipements_pompiers/GROUPE_SITRA_010_1.jpeg"
        ]
    },
    // domotique
    {
        img: "/rubriques/produits/authentique/categories/controle_acces/centrale_controle_d_acces.jpg",
        title: "Centrale contrôle d'accès",
        categorie: "domotique",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/domotique/controle-d_acces-temps-reel-550_0x200.jpg",
        title: "Contrôle d'accès temps réel",
        categorie: "domotique",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/domotique/acces_des_images_a_distance.jpg",
        title: "Accès des images à distance",
        categorie: "domotique",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/domotique/installation_complete_videosurveillance.png",
        title: "Installation complete videosurveillance",
        categorie: "domotique",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/domotique/bouton_poussoir.jpg",
        title: "Bouton poussoir",
        categorie: "domotique",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/domotique/centrale_anti_intrusion_AVEC_multitude_DE_SERVICE_PERSONNALISABLE.jpg",
        title: "Centrale anti intrusion avec multitude de service personalisable",
        categorie: "domotique",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/domotique/coffre_fort.jpg",
        title: "Coffre fort",
        categorie: "domotique",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/domotique/contact_porte.jpg",
        title: "Contact porte",
        categorie: "domotique",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/domotique/detecteur_de_mouvement.jpg",
        title: "Détecteur de mouvement",
        categorie: "domotique",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    // remorques_et_equipements_contre_les_feux_de_foret_et_incendie
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/camion_pompier_1.png",
        title: "Camion pompier",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/camion_pompier.jpeg"
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/GROUPE_SITRA_011.jpeg",
        title: "Find Name ...",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/GROUPE_SITRA_012.jpeg",
        title: "Remorques incendie",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/GROUPE_SITRA_014.jpeg",
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/GROUPE_SITRA_014_1.jpeg",
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/GROUPE_SITRA_014_2.jpeg",
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_incendie_1.png"
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Battes_a_feu.jpeg",
        title: "Battes à feu",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Rateaux_4_dents.jpeg",
        title: "Rateaux 4 dents",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_incendie_accessoire_1.png",
        title: "Remorque incendie accessoire 1",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_incendie_accessoire_2.png",
        title: "Remorque incendie accessoire 2",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_incendie_accessoire_3.png",
        title: "Remorque incendie accessoire 3",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_incendie_accessoire_4.png",
        title: "Remorque incendie accessoire 4",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_incendie_accessoire_5.png",
        title: "Remorque incendie accessoire 5",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_incendie_accessoire_6.png",
        title: "Remorque incendie accessoire 6",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_incendie_accessoire_7.png",
        title: "Remorque incendie accessoire 7",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_incendie_accessoire_8.png",
        title: "Remorque incendie accessoire 8",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_eclairage_1.png",
        title: "Remorque eclairage",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_eclairage_2.png",
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_eclairage_3.png",
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_eclairage_4.png",
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_eclairage_5.png",
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_eclairage_accessoire_1.png",
        title: "Remorque éclairage accessoire",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
            "rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_eclairage_accessoire_2.png",
            "rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_eclairage_accessoire_3.png",
            "rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_eclairage_accessoire_4.png",
            "rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_eclairage_accessoire_5.png",
            "rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_eclairage_accessoire_6.png",
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/GROUPE_SITRA_017.jpeg",
        title: "Find Name ...",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_surveillance_1.png",
        title: "Remorque surveillance",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_surveillance_2.png",
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_surveillance_3.png",
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_surveillance_4.png",
            "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_surveillance_5.png",
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_surveillance_accessoire_1.png",
        title: "Remorque surveillance accessoire 1",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_surveillance_accessoire_2.png",
        title: "Remorque surveillance accessoire 2",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_surveillance_accessoire_3.png",
        title: "Remorque surveillance accessoire 3",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_surveillance_accessoire_4.png",
        title: "Remorque surveillance accessoire 4",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/remorques_et_equipements_contre_les_feux_de_foret_et_incendie/Remorque_surveillance_accessoire_5.png",
        title: "Remorque surveillance accessoire 5",
        categorie: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    // securite_incendie
    // Détection incendie et gaz
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/centrale_detection_01_voie_a_12_voies_ou_plus.jpg",
        title: "Centrale détection 01 voie a 12 voies ou plus",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/centrale_detection_extinction_auto_NF_CE_UL.jpg",
        title: "Centrale détection extinction auto NF CE UL",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/detecteur_optique.jpg",
        title: "Détecteur optique",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/detecteur_thermique.jpg",
        title: "Détecteur thermique",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/detecteur_thermo-velocimetrique.png",
        title: "Détecteur thermo-velocimetrique",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/indicateur_d_action_NF_CE_UL.jpg",
        title: "Indicateur d'action NF CE UL",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/sirene_d_alarme_NF_CE_UL.png",
        title: "Sirene d'alarme NF CE UL",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/casque-de-pompiers-f1sf-122845_0x200.jpg",
        title: "Casque de pompiers f1sf 122845",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/désenfumage.jpeg",
        title: "Désenfumage",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/extincteur-6l-eau-pulverisee_0x200.png",
        title: "Extincteur 6l eau pulverisee",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/Extincteur_automatique.jpg",
        title: "Extincteur automatique",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/Extincteur_co2_0x200.jpg",
        title: "Extincteur Co2",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/extincteur-lifebox-nf-poudre-1kg_0x200.png",
        title: "Extincteur lifebox nf poudre 1kg",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/Extinction automatique FM 200 ARGON  HFC227_0x200.jpg",
        title: "Extinction automatique FM 200 ARGON  HFC227",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/lance mousse_0x200.png",
        title: "Lance mousse",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/Pipe line pompe incendie_0x200.png",
        title: "Pipe line pompe incendie",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/porte_coupe_feu.jpg",
        title: "Porte coupe feu",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/poste_incendie_addictive.jpg",
        title: "Poste incendie addictive",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/poteau_incendie.jpg",
        title: "poteau incendie",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/robinet_incendie.png",
        title: "Robinet incendie",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/recharge_extincteur.jpg",
        title: "Recharge extincteur",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/consigne_de_securite.jpg",
        title: "Consigne de sécurite",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/panneaux_de_signalisation.jpg",
        title: "Panneaux de signalisation",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/paques.jpg",
        title: "Paques",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/plan_d_evacuation.jpg",
        title: "Plan d'évacuation",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
    {
        img: "/rubriques/produits/authentique/categories/securite_incendie/point_de_rassemblement.jpg",
        title: "Point de rassemblement",
        categorie: "securite_incendie",
        description: `Voir caracteristiques pour plus de détails !`,
        proprietes: [
            // {
            //     label: ` :`,
            //     data: ``
            // }
        ],
        othersImages: [
        ]
    },
]

export default function ProduitsComponent() {

    const [selectedProduits, setSelectedProduits] = useState([])
    const [selectedProduitDetails, setSelectedProduitDetails] = useState({})

    const [isOpenProduitDetails, setIsOpenProduitDetails] = React.useState(false)

    const openProduitDetail = () => {
        setIsOpenProduitDetails(true)
    }

    const closeProduitDetail = () => {
        setIsOpenProduitDetails(false)
    }

    const findByCategorie = (categorie) => {
        setSelectedProduits(produits.filter(produit => produit.categorie === categorie.code))
    }

    const showProduitDetails = (produit) => {
        openProduitDetail()
        setSelectedProduitDetails(produit)
    }

    useEffect(() => {
        findByCategorie({ code: "securite_incendie" })
    }, [])

    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Categories getProduitsByCategorie={findByCategorie} />
                </Grid>
                <Grid item lg={9} md={8} sm={6} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <TitleComponent icon={<ArticleIcon />} label={`Produits`} />
                                    {/* Gérer détails produits inchaAllah... */}
                                </Grid>
                                <Grid item xs={4}>
                                    <img
                                        src={"/illustrations/undraw_Product_teardown_re_m1pc.png"}
                                        alt={"Logo SITRA"}
                                        loading="lazy"
                                        sx={{ boxShadow: 2, border: 1 }}
                                        style={{ width: "100%" }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            selectedProduits && selectedProduits.length !== 0 ?
                                selectedProduits.map((produit, i) =>
                                    <Grid
                                        item
                                        lg={4}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                        key={i}
                                    >
                                        <Box onClick={() => { showProduitDetails(produit) }}>
                                            <ProduitItemComponent
                                                produit={produit}
                                            />
                                        </Box>
                                    </Grid>
                                )
                                :
                                <>
                                    <Grid item xs={12}>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            noWrap
                                            sx={{
                                                borderBottom: 3,
                                                borderBottomColor: 'secondary.main',
                                                color: 'secondary.main',
                                                boxShadow: 1,
                                                padding: 1,
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {"Aucun produit n'a été trouvé..."}
                                        </Typography>
                                        < img
                                            src={"/illustrations/undraw_Blank_canvas_re_2hwy.png"}
                                            alt={"Not Found Product..."}
                                            loading="lazy"
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                </>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <DialogComponent
                fullScreen
                isReadOnlyMode={true}
                isOpenModal={isOpenProduitDetails}
                handleCloseModalForm={closeProduitDetail}
                title={`Détails produit`}
                noValidButtonIcon={<HighlightOffIcon />}
                noValidButtonText={"Fermer"}
            >
                <ProduitDetails
                    produit={selectedProduitDetails}
                />
            </DialogComponent>

        </>
    );
}
