import React from 'react';
import { Typography } from '@mui/material';
import InfoItem from './InfoItemComponent';
import Map from './MapComponent';

export default function ContactPerCountry(props) {
    return (
        <>
            <Typography
                align='left'
                sx={{
                    color: "primary.main",
                    borderBottom: 3,
                    borderRadius: 3,
                    boxShadow: 1,
                    padding: 1
                }}
                variant="h4"
                gutterBottom
                component="div"
            >
                {props.country}
            </Typography>
            {props.infos && props.infos.map((info, i) =>
                <InfoItem key={i} icon={info.icon} title={info.title} info={info.label} />
            )}
            <Map title={props.mapInfo.title} lien={props.mapInfo.lien} />
        </>
    );
}
