import React from 'react';
import { Avatar, Container, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ContactComponent from '../rubriques/contact/ContactComponent';
import { styled } from '@mui/material/styles';
import Presentation from '../presentation/PresentationComponent';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
        marginTop: theme.spacing(2)
    },
}));

export default function FooterComponent() {
    return (
        <>
            <Box
                sx={{
                    bgcolor: 'secondary.main',
                    // minHeight: '100vh',
                    borderTopLeftRadius: 50,
                    borderTopRightRadius: 50,
                    borderTop: 15,
                    borderTopColor: 'secondary.dark',
                }}
                mt={2}
            >
                <Container>
                    <Grid container spacing={2} mb={3}>
                        <Grid item lg={4} md={4} sm={4} xs={12} mt={1}>
                            <Presentation />
                        </Grid>
                        <Grid item lg={8} md={8} sm={8} xs={12}>
                            <ContactComponent />
                        </Grid>
                    </Grid>
                </Container>
                <Box
                    sx={{
                        bgcolor: 'secondary.dark',
                        borderTopLeftRadius: "50%",
                        borderTopRightRadius: "50%",
                        borderTop: 15,
                        borderTopColor: 'primary.main',
                    }}
                    pt={2}
                >
                    <Container>
                        <Root>
                            <Divider>
                                <Avatar alt="Logo SITRA" src="/logo.jpg" />
                            </Divider>
                            <Typography
                                variant="div"
                                component="div"
                                align='center'
                                gutterBottom
                                sx={{
                                    mt: 1,
                                    p: 1,
                                    fontWeight: 'fontWeightLight',
                                    color: 'primary.main',
                                }}
                            >
                                Copyright &copy; Groupe Sitra {new Date().getFullYear()} / Développer par Groupe Idyal
                            </Typography>
                        </Root>
                    </Container>
                </Box>
            </Box>
        </>
    );
}
