import React from 'react';
import { Avatar, Chip, Divider } from '@mui/material';
import { Box } from '@mui/system';
import ContentText from '../autres/ContentTextComponent';
import ReseauSociaux from '../footer/ReseauSociauxComponent';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
        marginTop: theme.spacing(2)
    },
}));

export default function Presentation() {
    return (
        <>
            <Box
                sx={
                    {
                        boxShadow: 15,
                        marginBottom: 2,
                        border: 5,
                        borderColor: 'secondary.main',
                        borderBottom: 15,
                        borderBottomColor: 'secondary.main',
                        borderBottomLeftRadius: "50%",
                        borderBottomRightRadius: "50%",
                    }
                }
            >
                <img
                    src={"/sitra-logo.png"}
                    alt={"Logo Sitra"}
                    loading="lazy"
                    style={
                        {
                            width: "100%",
                            borderBottomLeftRadius: "50%",
                            borderBottomRightRadius: "50%",
                            display: 'block',
                            marginRight: 'auto',
                        }
                    }
                />
            </Box>
            <Root>
                <Divider>
                    <Avatar alt="Logo SITRA" src="/logo.jpg" />
                </Divider>
                <ContentText>
                    Le groupe SITRA a une filiale à Dakar au Sénégal et à Abidjan en Côte d’Ivoire, la société est spécialisée dans plusieurs domaines d’activité particulièrement la sécurité incendie et environnement.
                </ContentText>
                <ContentText>
                    SITRA est une équipe d’Ingénieurs et de professionnels évoluant dans  plusieurs secteurs et maîtrisant parfaitement son métier dans le respect de la règlementation et des valeurs de notre société.
                </ContentText>
                <Divider>
                    <Chip label="Réseaux sociaux" color='primary' />
                </Divider>
                {/* Réseaux sociaux... inchaAllah! */}
                <ReseauSociaux />
            </Root>

        </>
    );
}
