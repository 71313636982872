import React from 'react';
import { Container, createTheme, Grid, ThemeProvider } from '@mui/material';
import './App.css';
import Header from './components/header/HeaderComponent';
import CarouselComponent from './components/carousel/CarouselComponent';
import QuiSommesNous from './components/rubriques/QuiSommesNousComponent';
import ProduitsComponent from './components/rubriques/produits/ProduitsComponent';
import FooterComponent from './components/footer/FooterComponent';
import Catalogue from './components/catalogue/CatalogueComponent';
import Partenaires from './components/rubriques/partenaires/PartenairesComponent';

// Code couleur principale (orange) : #ea851d
// Code couleur segondaire (vert) : #32682e, #468e42

const appTheme = createTheme({
    palette: {
        primary: {
            main: '#ea851d'
        },
        secondary: {
            dark: '#32682e',
            main: '#468e42'
        },
    },
});

const images = [
    {
        label: 'Sécurité incendie',
        imgPath: '/slide-images/Slide_1_Securite_incendie.png',
    },
    {
        label: 'Equipement Pompier',
        imgPath: '/slide-images/Slide_2_Equipement_Pompier.png',
    },
    {
        label: 'Domotique',
        imgPath: '/slide-images/Slide_3_Domotique.png',
    },
    {
        label: 'Remorques...',
        imgPath: '/slide-images/Slide_4_Remorques.png',
    },
    {
        label: 'Solution Informatique',
        imgPath: '/slide-images/Slide_5_Solution_Informatique.png',
    },
];


function App() {

    return (
        <div className="App">
            <ThemeProvider theme={appTheme}>
                <Header />
                <div id='Accueil'>
                    <CarouselComponent images={images} />
                </div>
                <Container maxWidth="lg">
                    <div id='Qui somme nous?'>
                        <QuiSommesNous />
                    </div>
                    <div id='Produits'>
                        <ProduitsComponent />
                    </div>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={5} sm={6} xs={12}>
                            <div id='Catalogue'>
                                <Catalogue />
                            </div>
                        </Grid>
                        <Grid item lg={8} md={7} sm={6} xs={12}>
                            <div id='Partenaires'>
                                <Partenaires />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <div id='Contact'>
                    <FooterComponent />
                </div>
            </ThemeProvider>
        </div>
    );
}

export default App;
