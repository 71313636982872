import React from 'react';
import TitleComponent from '../../autres/TitleComponent';
import CategoryIcon from '@mui/icons-material/Category';
import { List } from '@mui/material';
import { Avatar, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

const categories = [
    {
        code: "securite_incendie",
        label: "Sécurite incendie",
    },
    // {
    //     code: "equipements_anti_feu",
    //     label: "Equipements anti-feu",
    // },
    {
        code: "equipements_pompiers",
        label: "Equipements pompiers",
    },
    {
        code: "domotique",
        label: "Domotique",
    },
    {
        code: "remorques_et_equipements_contre_les_feux_de_foret_et_incendie",
        label: "Remorques et équipements contre les feux de forêt et incendie",
    },
    {
        code: "solutions_informatiques",
        label: "Solutions Informatiques",
    },
    // {
    //     code: "controle_acces",
    //     label: "Contrôle accès",
    // },
    // {
    //     code: "detection_incendie_et_gaz",
    //     label: "Détection incendie et gaz",
    // },
    // {
    //     code: "detection_intrusion",
    //     label: "Détection intrusion",
    // },
    // {
    //     code: "lutte_contre_incendie",
    //     label: "Lutte contre incendie",
    // },
    // {
    //     code: "signalisation",
    //     label: "Signalisation",
    // },
    // {
    //     code: "videosurveillance",
    //     label: "Vidéo Surveillance",
    // },
]

export default function Categories(props) {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const triggerEvent = (i, categorie) => {
        props.getProduitsByCategorie(categorie)
        setSelectedIndex(i);
    }

    return (
        <>
            <TitleComponent icon={<CategoryIcon />} label={`Categories`} />
            <img
                src={"/illustrations/undraw_Folder_re_j0i0.png"}
                alt={"Logo SITRA"}
                loading="lazy"
                style={{ width: "100%" }}
            />
            <nav aria-label="main mailbox folders">
                <List>
                    {categories.map((categorie, i) =>
                        <ListItem
                            key={i}
                            disablePadding
                            selected={i === selectedIndex}
                            sx={{
                                color: 'secondary.dark',
                                borderBottomLeftRadius: 15,
                                borderTopRightRadius: 15,
                                boxShadow: 2,
                                mb: 2,
                                borderBottom: 3,
                                borderColor: 'primary.main',
                                '&:hover': {
                                    bgcolor: 'primary.main',
                                    color: 'secondary.dark',
                                    fontWeight: 'bold',
                                    borderColor: 'secondary.dark',
                                    'div.MuiAvatar-root': {
                                        bgcolor: 'secondary.dark',
                                        borderRadius: 5
                                    },
                                    '.MuiSvgIcon-root': {
                                        color: 'primary.main',
                                    }
                                },
                                '&.Mui-selected': {
                                    bgcolor: 'primary.main',
                                    color: 'secondary.dark',
                                    fontWeight: 'bold',
                                    borderColor: 'secondary.dark',
                                    'div.MuiAvatar-root': {
                                        bgcolor: 'secondary.dark',
                                        borderRadius: 5
                                    },
                                    '.MuiSvgIcon-root': {
                                        color: 'primary.main',
                                    }
                                }
                            }}
                        >
                            <ListItemButton
                                onClick={() => triggerEvent(i, categorie)}
                            >
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: 'primary.main' }} variant='rounded'>
                                        {
                                            i !== selectedIndex ?
                                                <FolderIcon sx={{ color: 'secondary.dark' }} /> :
                                                <FolderOpenIcon />
                                        }
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primaryTypographyProps={{}}
                                    primary={categorie.label}
                                />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </nav>
        </>
    );
}
