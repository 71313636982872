import React from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TitleComponent from '../autres/TitleComponent';
import { Box, Grid } from '@mui/material';

export default function Catalogue() {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <img
                        src={"/illustrations/undraw_handcrafts_document.png"}
                        alt={"Logo SITRA"}
                        loading="lazy"
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TitleComponent label={`Catalogues`} icon={<PictureAsPdfIcon />} />
                </Grid>
            </Grid>
            {
                [
                    "/catalogue/CATALOGUE-GROUPE-SITRA.pdf",
                    "/catalogue/Présentation gamme Remorque - SITRA.pdf",
                    "/catalogue/ARTICLES PHOTOS.pdf"
                ].map((pdf, i) =>
                    <Box key={i} sx={{ mb: 2 }}>
                        <object
                            data={pdf}
                            type="application/pdf"
                            width="100%"
                            height={548}
                            style={{ borderRadius: 15 }}
                        >
                            <p>
                                <a href={pdf}>Télécharger le catalogue</a>
                            </p>
                        </object>
                    </Box>
                )
            }
        </>
    );
}
