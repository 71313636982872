import React from "react";
import {
    Avatar,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Slide,
    Typography
} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import FolderIcon from '@mui/icons-material/Folder';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function DialogComponent(props) {

    return (
        <Dialog
            fullScreen={props.fullScreen || false}
            fullWidth
            maxWidth={props.taille ? props.taille : "sm"}
            open={props.isOpenModal}
            onClose={props.handleCloseModalForm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
        >
            <DialogTitle id="alert-dialog-title">
                <Typography gutterBottom variant="h6" component="div" noWrap p={0}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: 'primary.main' }}>
                                {props.icon || <FolderIcon />}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={props.title}
                            primaryTypographyProps={{ variant: "h6" }}
                        />
                    </ListItem>
                    <IconButton
                        aria-label="close"
                        onClick={props.handleCloseModalForm}
                        style={{
                            position: 'absolute',
                            right: 5,
                            top: 5
                        }}
                    >
                        <HighlightOffIcon style={{ color: "rgb(211, 47, 47)" }} />
                    </IconButton>
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                {props.message}
                {props.children}
                {props.isLoading &&
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    disabled={props.isLoading}
                    size="small"
                    onClick={props.handleCloseModalForm}
                    sx={{
                        borderColor: "primary.main",
                        color: "primary.main"
                    }}
                >
                    {props.noValidButtonIcon || <ClearIcon />}{" "}{props.noValidButtonText || "Annuler"}
                </Button>
                {!props.isReadOnlyMode &&
                    <Button variant="contained" disabled={props.isLoading} size="small" onClick={props.handleValidButton} color="secondary">
                        {props.isLoading ?
                            <><CircularProgress size={16} color="inherit" /> {" "} Chargement...</>
                            :
                            <> {props.validButtonIcon || <CheckIcon />} {" "} {props.validButtonText || "Valider"} </>
                        }
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}