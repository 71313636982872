import React from 'react';
import { Grid } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import ReseauSocialItem from './ReseauSocialItemComponent';

const reseauSociaux = [
    {
        icon: <FacebookIcon />,
        name: "Facebook"
    },
    {
        icon: <YouTubeIcon />,
        name: "YouTube"
    },
    {
        icon: <TwitterIcon />,
        name: "Twitter"
    },
    {
        icon: <InstagramIcon />,
        name: "Instagram"
    }
]

export default function ReseauSociaux() {
    return (
        <>
            <Grid
                container
                spacing={4}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {reseauSociaux.map((reseauSocial, i) =>
                    <Grid key={i} item xs>
                        <ReseauSocialItem name={reseauSocial.name}>
                            {reseauSocial.icon}
                        </ReseauSocialItem>
                    </Grid>
                )}
            </Grid>
        </>
    );
}
