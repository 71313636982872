import { Box, Grid } from '@mui/material';
import React from 'react';
import TitleComponent from '../autres/TitleComponent';
import GroupsIcon from '@mui/icons-material/Groups';
import Presentation from '../presentation/PresentationComponent';
import TabsComponent from '../autres/TabsComponent';

export default function QuiSommesNous() {
    return (
        <>
            <TitleComponent icon={<GroupsIcon />} label={`Qui sommes-nous?`} />
            <Grid container spacing={2}>
                <Grid item lg={4} md={5} sm={6} xs={12}>
                    <img
                        src={"illustrations/bienvenue_illustration.png"}
                        alt={"Bienvenue"}
                        loading="lazy"
                        sx={{ boxShadow: 2, border: 1 }}
                        style={{ width: "100%" }}
                    />
                    <img
                        src={"illustrations/sitra-group-illustration.png"}
                        alt={"Logo SITRA"}
                        loading="lazy"
                        sx={{ boxShadow: 2, border: 1 }}
                        style={{ width: "100%" }}
                    />
                    <img
                        src={"/rubriques/qui-somme-nous/AFFICHE-GROUPE-SITRA.jpg"}
                        alt={"AFFICHE GROUPE SITRA"}
                        loading="lazy"
                        sx={{ boxShadow: 2, border: 1 }}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item lg={8} md={7} sm={6} xs={12}>
                    <Presentation />
                    <Box sx={{}} my={3}>
                        <TabsComponent
                            data={
                                [
                                    {
                                        title: "Sénégal",
                                        content:
                                            <>
                                                <img
                                                    src={"/rubriques/qui-somme-nous/qui_somme_nous_1.png"}
                                                    alt={"AFFICHE GROUPE SITRA"}
                                                    loading="lazy"
                                                    sx={{ boxShadow: 2, border: 1 }}
                                                    style={{ width: "100%" }}
                                                />
                                                <img
                                                    src={"/rubriques/qui-somme-nous/qui_somme_nous_2.png"}
                                                    alt={"AFFICHE GROUPE SITRA"}
                                                    loading="lazy"
                                                    sx={{ boxShadow: 2, border: 1 }}
                                                    style={{ width: "100%" }}
                                                />

                                            </>
                                    },
                                    {
                                        title: "Côte d'ivoire",
                                        content:
                                            <>
                                                <img
                                                    src={"/rubriques/qui-somme-nous/qui_somme_nous_ci_1.png"}
                                                    alt={"AFFICHE GROUPE SITRA"}
                                                    loading="lazy"
                                                    sx={{ boxShadow: 2, border: 1 }}
                                                    style={{ width: "100%" }}
                                                />
                                                <img
                                                    src={"/rubriques/qui-somme-nous/qui_somme_nous_ci_2.png"}
                                                    alt={"AFFICHE GROUPE SITRA"}
                                                    loading="lazy"
                                                    sx={{ boxShadow: 2, border: 1 }}
                                                    style={{ width: "100%" }}
                                                />
                                            </>
                                    }
                                ]
                            }
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
