import React from 'react';

export default function Logo() {
    return (
        <>
            <img
                src={"/logo.jpg"}
                alt={"Logo SITRA"}
                loading="lazy"
                style={{ borderRadius: 50, width: 50, height: 50 }}
            />
        </>
    );
}
