import React from 'react';
import { Chip, Divider, Grid } from '@mui/material';
import TitleComponent from '../../autres/TitleComponent';
import ContactPerCountry from './ContactPerCountryComponent';
import { Contacts as ContactsIcon } from '@mui/icons-material';
import CallIcon from '@mui/icons-material/Call';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';

export default function ContactComponent() {

    const senegaleseInfo = [
        {
            title: "Adresse",
            icon: <MapsHomeWorkIcon />,
            label: "15, rue Tolbiac - Dakar"
        },
        {
            title: "Directeur Générale",
            icon: <PersonIcon />,
            label: "M. SIDIBE Matar"
        },
        {
            title: "Téléphone",
            icon: <CallIcon />,
            label: "00221773290706",
        },
        {
            title: "Email",
            icon: <EmailIcon />,
            label: "msidibe@groupesitra.com",
        },
    ];
    const senegaleseMapInfo = {
        title: "Groupe Sipra Sénégal",
        lien: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d15438.969833322837!2d-17.43459288423415!3d14.670551719995325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1srue%20Tolbiac%20-%20Dakar!5e0!3m2!1sfr!2ssn!4v1643861074509!5m2!1sfr!2ssn"
    }

    const IvoryInfo = [
        {
            title: "Adresse",
            icon: <MapsHomeWorkIcon />,
            label: "Yopougon Toits Rouges Abidjan"
        },
        {
            title: "Directeur Générale",
            icon: <PersonIcon />,
            label: "M. COULIBALY Ibrahima"
        },
        {
            title: "Téléphone",
            icon: <CallIcon />,
            label: "002250757055758"
        },
        {
            title: "Email",
            icon: <EmailIcon />,
            label: "groupesitra@gmail.com ",
        },
    ];

    const ivoryMapInfo = {
        title: "Groupe Sipra Côte d'ivoir",
        lien:
            "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d31780.715500662256!2d-4.093630483273277!3d5.326550308104755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sYopougon%20Toits%20Rouges%20Abidjan!5e0!3m2!1sfr!2ssn!4v1643316215965!5m2!1sfr!2ssn"
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TitleComponent label={`Contacts`} icon={<ContactsIcon />} />
                </Grid>
                <Grid item xs>
                    <ContactPerCountry
                        country={"Sénégal"}
                        infos={senegaleseInfo}
                        mapInfo={senegaleseMapInfo}
                    />
                </Grid>
                <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                        display: {
                            xs: 'none', sm: 'none', md: 'flex'
                        }
                    }}
                >
                    <Chip variant="outlined" color="primary" label="Pays" />
                </Divider>
                <Grid item xs>
                    <ContactPerCountry
                        country={"Côte d'Ivoire"}
                        infos={IvoryInfo}
                        mapInfo={ivoryMapInfo}
                    />
                </Grid>
            </Grid>
        </>
    );
}
