import React from 'react';
import TitleComponent from '../../autres/TitleComponent';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Grid, IconButton, ImageListItemBar, Tooltip, tooltipClasses, Zoom } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";
import { styled } from '@mui/material/styles';

const itemData = [
    {
        img: '/partenaires/logo_isie.jpeg',
        title: 'ISIE Ivoire Sécurité Incendie et Environnement',
        pays: 'Côte d’Ivoire',
        rows: 1,
        cols: 1,
    },
    {
        img: '/partenaires/Logo-ADN-Solutions-HD.webp',
        title: 'ADN Solutions',
        pays: 'Sénégal',
    },
    {
        img: '/partenaires/AXCOL_LOGO.png',
        title: 'AXCOL',
        pays: 'Côte d’Ivoire',
    },
    {
        img: '/partenaires/Logo_FIRECO_EUROMAST.png',
        title: 'FIRECO EUROMAST',
        pays: 'France',
        rows: 1,
        cols: 1,
    },
    {
        img: '/partenaires/logo_elbac_court_couleur_charte_v2.svg',
        title: 'ELBAC Câbles ',
        pays: 'Sénégal',
    },
    {
        img: '/partenaires/Logo_IEC.jpeg',
        title: 'IEC : Ivoire Environnement Consulting',
        pays: 'Côte d’Ivoire',
        rows: 1,
        cols: 1,
    },
    {
        img: '/partenaires/logo_SMARTDEV_SARL.png',
        title: 'SMARTDEV SARL (Développement informatique)',
        pays: 'Côte d’Ivoire',
    },
    {
        img: '/partenaires/Logo-ARTEMIS.png',
        title: 'ARTEMIS',
        pays: 'Côte d’Ivoire',
    },
    {
        img: '/partenaires/logo_site_sodeci6.png',
        title: 'SODECI',
        pays: 'Sénégal',
        rows: 2,
        cols: 2,
    },
];

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        textAlign: 'center'
    },
}));

export default function Partenaires() {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <img
                        src={"/illustrations/undraw_Business_deal_re_up4u.png"}
                        alt={"Logo SITRA"}
                        loading="lazy"
                        sx={{ boxShadow: 2, border: 1 }}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TitleComponent icon={<VolunteerActivismIcon />} label={"Partenaires"} />
                </Grid>
            </Grid>
            <Box sx={{ mb: 2, bgcolor: 'primary.main', padding: 2, borderRadius: 5 }}>
                <ImageList cols={2}>
                    {itemData.map((item) => (
                        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                            <img
                                src={`${item.img}?w=248&fit=crop&auto=format`}
                                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                alt={item.title}
                                loading="lazy"
                                style={{ objectFit: 'contain' }}
                            />
                            <ImageListItemBar
                                title={item.title}
                                subtitle={item.pays}
                                actionIcon={
                                    <BootstrapTooltip
                                        TransitionComponent={Zoom}
                                        title={`${item.title} - ${item.pays}`}
                                        arrow
                                        followCursor
                                        leaveDelay={500}
                                        placement="top"
                                    >
                                        <IconButton
                                            sx={{ color: 'primary.main' }}
                                            aria-label={`info about ${item.title}`}
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    </BootstrapTooltip>
                                }
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </>
    );
}
