import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';

export default function InfoItem(props) {
    return (
        <>
            <ListItem sx={{ bgcolor: 'secondary.dark', borderRadius: 5, color: 'white', mb: 2 }}>
                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                        {props.icon || <FolderIcon />}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={props.info}
                    secondaryTypographyProps={{ color: 'primary.main' }}
                    secondary={props.title}
                />
            </ListItem>
        </>
    );
}
